import { apiConfig, service } from '@/api'
import { clone } from '@/shared'
import { storage } from '@/shared'
import { makeAutoObservable, runInAction } from 'mobx'
import { sleep } from '@/shared'
import { showToastError } from '@/shared'
import { SessionModel } from './models'

class AppStore {
    state = {
        init: false,
        loading: false,
        error: null,
    }
    session: SessionModel

    constructor() {
        makeAutoObservable(this)
    }

    setState(state: Partial<typeof this.state>) {
        this.state = {
            ...clone(this.state),
            ...state,
        }
    }

    /* -------------------------------------------------------------------------- */
    /*                                   Session                                  */
    /* -------------------------------------------------------------------------- */

    get is_booking_role() {
        return !!this.session.data.user.roles.find((role) => role.title.includes('ронирован'))
    }

    /**
     * Запрашиваем здесь все необходимые данные,
     * для первоначальной отрисовки приложения и пушим их в store/config
     */
    async initialize() {
        try {
            this.setState({ loading: true })

            storage.reversion()

            try {
                await service.auth.csrf()
                const session = await service.auth.session()

                runInAction(() => (this.session = session))
            } catch (err: any) {
                console.log(err)
                runInAction(() => (this.session = null))
            }

            this.setState({ init: true })

            setTimeout(() => {
                this.setState({ loading: false })
            }, 300)
        } catch (err: any) {
            console.log(err)
            this.setState({ loading: false, error: { message: err?.message, status: 400 } })
        }
    }

    async login(props: Parameters<typeof service.auth.login>[0]['data']) {
        try {
            if (this.state.loading) return

            this.setState({ loading: true })

            await sleep(300)

            await service.auth.csrf()
            await service.auth.login({
                data: props,
            })

            const session = await service.auth.session()

            runInAction(() => (this.session = session))
        } catch (err) {
            showToastError(err?.message)
        } finally {
            this.setState({ loading: false })
        }
    }

    async logout() {
        this.setState({ loading: true })
        try {
            //TODO: Перенести в адаптер
            await apiConfig.fetch.ilsa({ url: '/auth/logout', config: { method: 'POST' } })

            // Cookies.remove('XSRF-TOKEN', { domain: '.elisa-project.ru', path: '/' })

            this.session = null
        } catch (err) {
            console.log(err)
        } finally {
            this.setState({ loading: false })
        }
    }
}

export const appStore = new AppStore()
