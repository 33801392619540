import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { appStore } from '@/app/app.store'
import { AppError } from '@/app/ui/error/error'
import { routes } from '@/app/app.routes'
import { AppLayout } from '@/app/app.layout'
import { notifyContainer } from '@/theme/@deprecated/components/ui'
import { AuthPage } from '@/pages'

export const App = observer(() => {
    const mainRoutes = appStore.session && (
        <Routes>
            <Route path='/' element={<Navigate to={`/${appStore.session.data.tabs[0].label}`} />} />

            {appStore.session.data.tabs.map((tab) => (
                <React.Fragment key={tab?.label}>{routes[tab?.label]?.route}</React.Fragment>
            ))}

            <Route path='*' element={<AppError status={404} />} />
        </Routes>
    )

    const authRoutes = (
        <Routes>
            <Route path='*' element={<AuthPage />} />
        </Routes>
    )

    const renderRoutes = appStore.session ? mainRoutes : authRoutes

    return (
        <AppLayout>
            {renderRoutes}
            {notifyContainer}
        </AppLayout>
    )
})
